import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/pages/Home.vue')
  },
  {
    path: '/our_gelato',
    name: 'OurGelato',
    component: () => import('../views/pages/OurGelato.vue')
  },
  {
    path: '/gelato',
    name: 'Gelato',
    component: () => import('../views/pages/Gelato.vue')
  },
  {
    path: '/sorbetto_gelato',
    name: 'SorbettoGelato',
    component: () => import('../views/pages/SorbettoGelato.vue')
  },
  {
    path: '/special_flavours_gelato',
    name: 'SpecialFlavoursGelato',
    component: () => import('../views/pages/SpecialFlavoursGelato.vue')
  },
  {
    path: '/dessert',
    name: 'Dessert',
    component: () => import('../views/pages/Dessert.vue')
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import('../views/pages/Menu.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/pages/Blog.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
